<template>
  <div>
    <div class="mb-3">
      <label class="form-label">상품명</label>
      <input class="form-control" v-model="pdName" placeholder="상품명을 입력하세요."/>
    </div>

    <div class="mb-3">
      <label class="form-label">이미지 선택</label>
      <SelectImage ref="selectImage"/>
    </div>

    <div class="mb-3">
      <label class="form-label">카테고리</label>
      <SelectCategory ref="selectCategory"/>
    </div>

    <div class="mb-3">
      <label class="form-label">연락처</label>
      <input v-model="phone" class="form-control" placeholder="01011112222"/>
    </div>

    <b-button block variant="primary" style="width: 100%" @click="postCustomCode">
      요청
    </b-button>
  </div>
</template>

<script>
import SelectImage from "@/views/pages/cusomBarcode/atoms/selectImage";
import SelectCategory from "@/views/pages/cusomBarcode/atoms/selectCategory";
import {customBarcodeAction, customBarcodeMutation, customBarcodeState} from "@/state/modules/customBarcodeHelper";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import {msgModalOptCenter} from "@/components/globalFunctions";

export default {
  name: "registerInput",
  components: {SelectCategory, SelectImage},
  data() {
    return {
      pdName: '',
      phone: ''
    }
  },
  computed: {
    ...mapState(customBarcodeState),
    ...mapState(loggedInfoObject),
  },
  methods: {
    ...customBarcodeMutation,
    ...customBarcodeAction,

    async postCustomCode() {
      await this.setPostBarcode({'chk': 'pd', 'val': this.pdName})
      await this.setPostBarcode({'chk': 'phone', 'val': this.phone})

      if (this.checkPostEmpty()){
        await this.postBarcodeAct({'biznum': this.logged_info.biznum, 'barcodeData': this.postBarcode})
      }

      if (this.initChk == 'success') {
        this.pdName = ''
        this.phone = ''
        this.$refs.selectImage.initInput()
        this.$refs.selectCategory.initSelect()

        this.chkSuccess("")

        this.$bvModal.msgBoxOk('요청을 완료하였습니다.\n담당자 확인 대기 중입니다.', msgModalOptCenter);
      }
    },

    checkPostEmpty() {
      if (this.postBarcode.product_name == "") {
        this.$bvModal.msgBoxOk(`상품명이 비었습니다.\n상품명을 확인해주세요.`, msgModalOptCenter);

        return false
      } else if (this.postBarcode.img == '') {
        this.$bvModal.msgBoxOk('이미지를 선택해주세요.', msgModalOptCenter);

        return false
      } else if (this.postBarcode.category == '') {
        this.$bvModal.msgBoxOk('카테고리를 선택해주세요.', msgModalOptCenter);

        return false
      } else if (this.postBarcode.phone == '') {
        this.$bvModal.msgBoxOk('연락처를 입력해주세요.', msgModalOptCenter);

        return false
      }

      return true
    }
  }
}
</script>

<style scoped>

</style>
