<template>
  <div class="input-group" style="width: 40%">
    <input
        type="text"
        class="form-control"
        style="display: inline-flex; width: 30%"
        placeholder="상품명 또는 바코드를 입력해주세요."
        aria-label="search result"
        aria-describedby="button-search"
        ref="searchKeyword"
        v-model="srchKeyword"
        @keypress.enter="srchBarcode(srchKeyword)"
    />
    <button
        class="btn btn-primary"
        style="display: inline-flex"
        type="button"
        id="button-search"
        @click="srchBarcode(srchKeyword)"
    >
      검색
    </button>
  </div>
</template>

<script>
import {customBarcodeAction} from "@/state/modules/customBarcodeHelper";

export default {
  name: "srchInput",

  data() {
    return {
      srchKeyword: ""
    }
  },

  methods: {
    ...customBarcodeAction,
  }
}
</script>

<style scoped>

</style>
