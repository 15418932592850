<script>
/**
 * Page-header compponent
 */
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: String,
      default: '',
    },
    btnName: {
      type: String,
      default: '',
    },
  },
  methods: {
    btnAction(pageTitle) {
      console.log(pageTitle);
    },
    btnHide(isHidden) {
      return isHidden
    }
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <span style="float: left; padding-left: 8px;">
          <h4 class="mb-0 font-size-18">{{ title }} </h4>
          <span :items="items" class="font-size-14" style="color: #777777; text-align: left;">{{ items }}</span>
        </span>
        <span class="page-title-right d-flex align-items-center md-0" style="float: right; padding-right: 8px;">
          <a
              href="javascript: void(0);"
              class="btn btn-primary btn-soft w-100"
              @click="btnAction(title)"
              v-if="btnName !== ''"
          >
            {{ btnName }}
          </a>
        </span>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
