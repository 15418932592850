<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">신규요청</h4>
    </div>

    <div class="card-body">
      <RegisterInput/>
    </div>
  </div>
</template>

<script>
import RegisterInput from "@/views/pages/cusomBarcode/module/register/registerInput";

export default {
  name: "registerBarcode",
  components: {RegisterInput}

}
</script>

<style scoped>

</style>
