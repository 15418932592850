<template>
  <div>
    <div class="p-0">
      <input class="form-control" type="file" style="display: inline; vertical-align: middle" accept="image/jpg, image/jpeg, image/png, image/gif"
             ref="imgInput" @change="previewImage"/>
    </div>

    <div class="col-lg-3 p-0">

      <b-button v-if="false" class="btn btn-soft-dark m-0" style="display: inline; vertical-align: middle; width: 100%;"
                @click="$bvModal.show('modal-urlImg')">
        이미지 검색
      </b-button>

      <b-modal centered ok-only id="modal-urlImg" title="이미지 선택" body-class="p-0 m-0" ok-title="취소"
               ok-variant="secondary">
        <div class="modal-body p-0">
          <div class="input-group mb-3" style="padding: 16px 16px 0 16px">
            <input type="text" class="form-control"/>
            <div class="input-group-btn">
              <button type="button" class="btn btn-primary" size="sm"
                      style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                검색
              </button>
            </div>
          </div>
          <div style="width: 100%; max-height: 480px; overflow-y: scroll;">
            <div style="cursor: pointer; width: 33.33%; display: inline-block;">
              <div
                  style="width: 100%; padding-top: 100%; background-repeat: no-repeat; background-position: center; background-size: contain;"/>
              <!--:style="{'background-image': `url(${row.img_path})`}"-->
            </div>
          </div>
        </div>
      </b-modal>

    </div>

  </div>
</template>

<script>
import {customBarcodeMutation} from "@/state/modules/customBarcodeHelper";

export default {
  name: "selectImage",
  data() {
    return {
      fileImg: '',
    }
  },
  methods: {
    ...customBarcodeMutation,
    async previewImage() {
      console.log('previewimage')
      this.urlImg = "";
      let input = event.target;
      if (input.files && input.files[0]) {
        this.fileImg = input.files[0];
        console.log(this.fileImg);
      } else {
        this.fileImg = "";
      }

      await this.setPostBarcode({'chk': 'img', 'val': this.fileImg})
    },

    initInput() {
      this.fileImg = ''
      this.$refs.imgInput.value = ''
    }
  }
}
</script>

<style scoped>

</style>
