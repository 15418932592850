<template>
  <div>
      <input
          readonly
          type="text"
          placeholder="분류선택"
          class="form-control"
          style="background-color: #fff"
          v-model="categoryName"
          @click="$bvModal.show('modal-category')"
      />
      <b-modal
          centered
          id="modal-category"
          title="분류선택"
          body-class="p-0 m-0"
          ok-title="취소"
          ok-variant="secondary"

          ok-only
      >
        <div
            class="modal-body"
            style="max-height: 500px; overflow-y: scroll"
        >
          <tree :data="categoryList" @node:selected="selectCategory"/>
        </div>
      </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import LiquorTree from "liquor-tree";
import "liquor-tree/dist/liquor-tree.umd";
import {mapState} from "vuex";
import {customBarcodeMutation, customBarcodeState} from "@/state/modules/customBarcodeHelper";


export default {
  name: "selectCategory",
  components: {
    tree: LiquorTree,
  },
  data() {
    return {
      categoryList: [],
      categoryName: '',
      categoryId: ''
    }
  },
  computed: {
    ...mapState(customBarcodeState)
  },
  beforeMount() {
    this.getCategory()
  },
  methods: {
    ...customBarcodeMutation,
    getCategory() {
      let url = "/api/category/children/all";

      axios
          .get(url)
          .then((res) => {
            //console.log(res.data)
            this.categoryList = res.data;
          })
          .catch((err) => console.log(err));
    },

    //분류 선택
    selectCategory(node) {
      if (node.depth == 2) {
        this.categoryName = node.text;

        this.setPostBarcode({'chk' : 'cat', 'val' : this.categoryName})

        this.$bvModal.hide("modal-category");
      }
    },

    initSelect() {
      this.categoryName = ''
      this.categoryId = ''
    }
  }
}
</script>

<style scoped>

</style>
