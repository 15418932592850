import '../assets/css/globalCss.css'

export const msgConfirmOptCenter = {
    // bodyTextVariant: "center",
    centered: true,
    okTitle: "네",
    cancelTitle: "아니요",
    noCloseOnBackdrop: true,
    bodyClass: 'bodyCss'
};

export const msgModalOptCenter = {
    // bodyTextVariant: "center",
    centered: true,
    okTitle: "확인",
    bodyClass: 'bodyCss'
};
