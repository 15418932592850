<template>
  <div>
    <b-card no-body class="overflow-hidden">
      <div class="card-body" style="padding: 8px">
        <div class=" d-flex align-items-center">
          <div class="flex-shrink-0">
            <img :src="`${row.img_path}`" style="width: 100px; height: 100px; border-radius: 0.25rem; object-fit: cover; "/>
            <img v-if="row.img_path === ''" :src="`https://cdn1.critalk.com/static/images/no_image.png`"
                 style="width: 100px; height: 100px; border-radius: 0.25rem; object-fit: cover;"/>
          </div>

          <table style="width: 100%">
            <tr>
              <td style="padding-left: 1em; vertical-align: top">
                <!--// 중앙-->
                <div style="font-size: 14px; display: inline">
                  <label class="form-check-label" style="font-weight: bold">{{ row.barcode }}</label><br/>
                  <label class="form-check-label" style="font-size: 1em">{{ row.product_name }}</label><br/>
                  <label class="form-check-label" style="
                                  font-size: 1em;
                                  color: #a0a0a0;
                                  margin-bottom: 6px;
                                ">{{ row.cat1_name }} > {{ row.cat2_name }} > {{ row.cat3_name.replaceAll('_', '') }}</label><br/>
                  <small class="text-muted" style="font-size: 14px"></small>
                </div>
              </td>

              <td style="width: 1em; text-align: right">
                <!--// 우측-->
              </td>
            </tr>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "srchCell",
  props: ['row'],
  created() {
    console.log('row', this.row)
  }
}
</script>

<style scoped>

</style>
