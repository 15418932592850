import { render, staticRenderFns } from "./registerInput.vue?vue&type=template&id=b449570e&scoped=true"
import script from "./registerInput.vue?vue&type=script&lang=js"
export * from "./registerInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b449570e",
  null
  
)

export default component.exports